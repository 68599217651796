(function(){
    'use strict';

    angular
        .module('elogbooks.admin.sites')
        .controller('SiteDetailsController', SiteDetailsController);

    SiteDetailsController.$inject = [
        'siteResponse',
        'approversResponse',
        'siteNotesCollectionResponse',
        'siteNoteTypesCollectionResponse',
        'quoteThresholdsCollectionResponse',
        'siteAssociatesService',
        'rootResourceResponse',
        'userManager'
    ];

    function SiteDetailsController (
        siteResponse,
        approversResponse,
        siteNotesCollectionResponse,
        siteNoteTypesCollectionResponse,
        quoteThresholdsCollectionResponse,
        siteAssociatesService,
        rootResourceResponse,
        userManager
    ) {
        var vm = this;
        vm.site = siteResponse.getData();
        vm.siteNotes = siteNotesCollectionResponse.getData().siteNotes;

        vm.siteNoteTypesById = [];
        angular.forEach(siteNoteTypesCollectionResponse.sitenotetypes, function(noteType) {
            var noteTypeId = noteType.id;
            var noteTypeName = noteType.name;
            vm.siteNoteTypesById[noteTypeId] = noteTypeName;
        });

        vm.isClientAdmin = userManager.hasPermission('user_permission_client_admin_manage_sites') && !userManager.hasPermission('user_permission_view_sites');
        vm.canAccessPartnerships = userManager.hasPermission('site_permission_view_partnerships') || userManager.hasPermission('user_permission_client_admin_edit_partnerships');

        vm.quoteThresholdsCollection = quoteThresholdsCollectionResponse.quoteThresholds;
        vm.associatesCollection = siteAssociatesService.getSiteAssociates(siteResponse, rootResourceResponse);
        vm.approvers = approversResponse;
        vm.canAccessPartnerships = userManager.hasPermission('site_permission_view_partnerships') || userManager.hasPermission('user_permission_client_admin_edit_partnerships');
    }
})();
