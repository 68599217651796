(function() {
    'use strict';

    angular
        .module('elogbooks.user.service-providers')
        .config(registerRoutes)
        .config(registerRoutesServiceProviderWebHooks)
        .config(registerRoutesPartnerships);

    function registerRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.user.service-providers', {
                abstract: true,
                template: '<bookerize></bookerize>',
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state('dashboard.user.service-providers.manage', {
                url: '/service-providers',
                abstract: true,
                parent: 'dashboard.user.service-providers',
                views: parseBook({
                    'elogbooks-center@dashboard.user.service-providers': {
                        templateUrl: '/modules/user/service-providers/manage-service-providers.html',
                        controller: 'ManageServiceProvidersListController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    tabConfig: function(userManager) {
                        return {
                            listUrl: 'dashboard.user.service-providers.list',
                            listAllUrl: 'dashboard.user.service-providers.all-listed',
                            uiViewUrl: 'elogbooks-right@dashboard.user.service-providers.manage'
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SERVICE_PROVIDERS" | translate }}'
                }
            })
            .state('dashboard.user.service-providers.all-listed', {
                url: '/all-service-providers?page&limit&order&{name:any}&contractorApproved&serviceProvidersTelephone&serviceProvidersEmail&serviceProvidersAddress&' +
                    '&partnerships&trainedAtStart&trainedAtEnd&activeAtStart&activeAtEnd&prosure360Status&serviceProviderGroups',
                parent: 'dashboard.user.service-providers.manage',
                data: {
                    permissions: ['site_permission_view_all_service_providers']
                },
                views: parseBook({
                    'elogbooks-right@dashboard.user.service-providers.manage': {
                        templateUrl: '/modules/user/service-providers/list-views/service-providers-list/service-providers-list.html',
                        controller: 'UserServiceProvidersListController',
                        controllerAs: 'vm'
                    }
                }),
                params: {
                    page: '1',
                    limit: '30',
                    order: 'name',
                    name: null,
                    serviceProvidersTelephone: null,
                    serviceProvidersEmail: null,
                    serviceProvidersAddress: null,
                    serviceProviderGroups: {array: true},
                    serviceProvidersStatus: null,
                    partnerships: {array: true},
                    status: 'active',
                    trainedAtStart: null,
                    trainedAtEnd: null,
                    activeAtStart: null,
                    activeAtEnd: null,
                    contractorApproved: null,
                    listAll: true,
                    prosure360Status: {array: true}
                },
                resolve: {
                    serviceProviderCollection: function($stateParams, apiClient, rootResourceResponse, paramsService) {
                        var params = angular.extend({}, $stateParams, {
                            'partnerships[]': $stateParams.partnerships,
                            'prosure360Status[]': paramsService.prosure360Status,
                            'serviceProviderGroups[]': $stateParams.serviceProviderGroups
                        });

                        return apiClient.get(rootResourceResponse.getLink('serviceproviders'), params).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    serviceProviderGroupsResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('serviceprovidergroups')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ALL_SERVICE_PROVIDERS" | translate }}'
                }
            })
            .state('dashboard.user.service-providers.list', {
                url: '/service-providers?page&limit&order&{name:any}&contractorApproved&serviceProvidersTelephone&serviceProvidersEmail&serviceProvidersAddress&serviceProviderGroup&partnerships&trainedAtStart&trainedAtEnd&activeAtStart&activeAtEnd&prosure360Status&serviceProviderGroups',
                parent: 'dashboard.user.service-providers.manage',
                views: parseBook({
                    'elogbooks-right@dashboard.user.service-providers.manage': {
                        templateUrl: '/modules/user/service-providers/list-views/service-providers-list/service-providers-list.html',
                        controller: 'UserServiceProvidersListController',
                        controllerAs: 'vm'
                    }
                }),
                params: {
                    page: '1',
                    limit: '30',
                    order: 'name',
                    name: null,
                    serviceProvidersTelephone: null,
                    serviceProvidersEmail: null,
                    serviceProvidersAddress: null,
                    serviceProviderGroup: null,
                    serviceProviderGroups: {array: true},
                    serviceProvidersStatus: null,
                    partnerships: {array: true},
                    status: null,
                    trainedAtStart: null,
                    trainedAtEnd: null,
                    activeAtStart: null,
                    activeAtEnd: null,
                    contractorApproved: null,
                    listAll: false,
                    prosure360Status: {array: true}
                },
                resolve: {
                    serviceProviderCollection: function(paramsService, apiClient, user) {
                        var params = paramsService;

                        var override = {
                            'partnerships[]': paramsService.partnerships,
                            'prosure360Status[]': paramsService.prosure360Status,
                            'serviceProviderGroups[]': paramsService.serviceProviderGroups
                        };

                        params = angular.extend({}, params, override);

                        return apiClient.get(user.getLink('serviceproviders'), params).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    serviceProviderGroupsResponse: function() {
                        return false;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"MY_SERVICE_PROVIDERS" | translate }}'
                }
            })
            .state('dashboard.user.service-providers.list.details', {
                url: '/details/{resource}',
                parent: 'dashboard.user.service-providers.list',
                abstract: true,
                views: parseBook({
                    'elogbooks-right@dashboard.user.service-providers': {
                        templateUrl: '/modules/user/service-providers/details/service-providers-details.html',
                        controller: 'UserServiceProvidersDetailsController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    serviceProviderResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.resource.decode()).then(function(response) {
                            return response || apiClient.noResourceFound('dashboard.user.service-providers.list');
                        });
                    },
                    userResponse: function (user) {
                        return user;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().serviceProviderResponse.name }}'
                }
            })
            .state('dashboard.user.service-providers.list.details.info', {
                url: '/info',
                parent: 'dashboard.user.service-providers.list.details',
                views: parseBook({
                    'elogbooks-right@dashboard.user.service-providers.list.details': {
                        templateUrl: '/modules/user/service-providers/details/info/service-providers-info.html',
                        controller: 'UserServiceProvidersDetailsInfoController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            })
            .state('dashboard.user.service-providers.list.details.contacts', {
                url: '/contacts',
                parent: 'dashboard.user.service-providers.list.details',
                views: parseBook({
                    'elogbooks-right@dashboard.user.service-providers.list.details': {
                        templateUrl: '/modules/user/service-providers/details/contacts/service-providers-contacts.html',
                        controller: 'UserServiceProvidersDetailsContactsController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    contactInformationResponse: function(apiClient, serviceProviderResponse) {
                        return apiClient.get(serviceProviderResponse.getLink('contactinformation'));
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"DETAILS" | translate }}'
                }
            })
            .state('dashboard.user.service-providers.list.details.primary-operatives', {
                url: '/primary-operatives',
                parent: 'dashboard.user.service-providers.list.details',
                views: parseBook({
                    'elogbooks-right@dashboard.user.service-providers.list.details': {
                        templateUrl: '/modules/common/primary-operatives/primary-operatives-list.html',
                        controller: 'CommonServiceProvidersDetailsPrimaryOperativesController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"PRIMARY_OPERATIVES" | translate }}'
                }
            })
            .state('dashboard.user.service-providers.list.details.performance', {
                url: '/performance',
                parent: 'dashboard.user.service-providers.list.details',
                views: parseBook({
                    'elogbooks-right@dashboard.user.service-providers.list.details': {
                        templateUrl: '/modules/common/performance-statistics/performance-statistics.html',
                        controller: 'CommonPerformanceStatisticsController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    serviceProviderPerformanceStatisticsResponse: function (apiClient, user, serviceProviderResponse, selectedSiteResponse) {
                        var params = {serviceProvider: serviceProviderResponse.id};
                        if (selectedSiteResponse) {
                            params.site = selectedSiteResponse.id;
                        }

                        return apiClient.get(user.getLink('performance-statistics'), params);
                    },
                    systemPerformanceStatisticsResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('systemperformancestatistics')).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"PERFORMANCE_STATISTICS" | translate }}'
                }
            })
            .state('dashboard.user.service-providers.list.details.reporter-feedback', {
                url: '/reporter-feedback?feedbackPage&feedbackLimit&feedbackOrder&job&score&contactable&dateRange&operative&site',
                parent: 'dashboard.user.service-providers.list.details',
                views: parseBook({
                    'elogbooks-right@dashboard.user.service-providers.list.details': {
                        templateUrl: '/modules/user/service-providers/details/reporter-feedback/reporter-feedback.html',
                        controller: 'UserServiceProvidersReporterFeedbackController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    spReporterFeedbackCollectionResponse: function (apiClient, serviceProviderResponse, $stateParams) {
                        var params =  angular.copy(angular.extend({}, $stateParams, {
                            order: $stateParams.feedbackOrder,
                            page: $stateParams.feedbackPage,
                            limit: $stateParams.feedbackLimit,
                            operative: $stateParams.operative ? JSON.parse($stateParams.operative).v : null,
                            site: $stateParams.site ? JSON.parse($stateParams.site).v : null,
                        }));

                        return apiClient.get(serviceProviderResponse.getLink('jobsreporterfeedback'), params).then(function(response) {
                            return response;
                        });
                    },
                    operativeCollectionResponse: function (apiClient, serviceProviderResponse) {
                        return apiClient.get(serviceProviderResponse.getLink('operatives'), {}).then(function(response) {
                            return response;
                        });
                    },
                    siteCollectionResponse: function(apiClient, userManager) {
                        return userManager.getUser().then(function(user) {
                            return apiClient.get(user.getLink('sites')).then(function(response) {
                                return response;
                            });
                        });
                    }
                },
                params: {
                    feedbackPage: '1',
                    feedbackLimit: '30',
                    feedbackOrder: '-job',
                    job: null,
                    score: null,
                    contactable: null,
                    dateRange: null,
                    operative: null,
                    site: null
                },
                ncyBreadcrumb: {
                    label: '{{ ::"FEEDBACK" | translate }}'
                }
            });
        }

    function registerRoutesServiceProviderWebHooks($stateProvider) {
        $stateProvider
            .state('dashboard.user.service-providers.list.details.webhooksinfo', {
                url: '/webhooks/{webhookResource}',
                parent: 'dashboard.user.service-providers.list.details',
                views: parseBook({
                    'elogbooks-right@dashboard.user.service-providers.list.details': {
                        templateUrl: '/modules/common/webhooks/details/info/webhooks-info.html',
                        controller: 'WebhooksDetailsInfoController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    config: function () {
                        return {
                            noBorder: false
                        };
                    },
                    webhookResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.webhookResource.decode());
                    },
                    serviceProviderResponse: function (serviceProviderResponse) {
                        return serviceProviderResponse;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ "WEBHOOK" | translate }}'
                }
            })
            .state('dashboard.user.service-providers.list.details.webhooksinfo.add', {
                url: '/add',
                parent: 'dashboard.user.service-providers.list.details.webhooksinfo',
                views: parseBook({
                    'elogbooks-right@dashboard.user.service-providers.list.details': {
                        templateUrl: '/modules/common/webhooks/addedit/webhooks-addedit.html',
                        controller: 'WebhooksAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    webhookResponse: function() {
                        return {};
                    },
                    webhookCreateLink: function(serviceProviderResponse) {
                        return serviceProviderResponse.getLink('add-webhook');
                    },
                    config: function() {
                        return {
                            noBorder: false
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.user.service-providers.list.details.webhooksinfo.edit', {
                url: '/edit',
                parent: 'dashboard.user.service-providers.list.details.webhooksinfo',
                views: parseBook({
                    'elogbooks-right@dashboard.user.service-providers.list.details': {
                        templateUrl: '/modules/common/webhooks/addedit/webhooks-addedit.html',
                        controller: 'WebhooksAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    webhookCreateLink: function() {
                        return null;
                    },
                    config: function() {
                        return {
                            noBorder: false
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerRoutesPartnerships($stateProvider) {
        $stateProvider
            .state('dashboard.user.service-providers.list.details.partnerships', {
                url: '/partnerships?partnershipPage&partnershipLimit&partnershipOrder&partnershipSite&partnershipActive&successShareSetup&site',
                parent: 'dashboard.user.service-providers.list.details',
                views: {
                    '@dashboard.user.service-providers.list.details': {
                        templateUrl: '/modules/user/service-providers/details/partnerships/partnerships-list.html',
                        controller: 'UserServiceProviderPartnershipsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    partnershipsResponse: function(apiClient, serviceProviderResponse, $stateParams) {
                        return apiClient.get(serviceProviderResponse.getLink('partnerships'), angular.extend({}, $stateParams, {
                            page: $stateParams.partnershipPage,
                            limit: $stateParams.partnershipLimit,
                            order: $stateParams.partnershipOrder,
                            active: $stateParams.partnershipActive,
                            site: $stateParams.site
                        }));
                    }
                },
                params: {
                    partnershipPage: '1',
                    partnershipLimit: '30',
                    partnershipOrder: 'site',
                    partnershipActive: null,
                    site: null
                },
                ncyBreadcrumb: {
                    label: '{{ ::"PARTNERSHIPS" | translate }}'
                }
            })
            .state('dashboard.user.service-providers.list.details.partnerships.add', {
                url: '/add',
                parent: 'dashboard.user.service-providers.list.details.partnerships',
                views: {
                    '@dashboard.user.service-providers.list.details': {
                        templateUrl: '/modules/user/service-providers/details/partnerships/add/partnerships-form.html',
                        controller: 'ServiceProviderPartnershipsAddController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    siteCollection: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('sites')).then(function(response) {
                            return response;
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.user.service-providers.list.details.partnerships.details', {
                url: '/{partnershipResource}?priorityPage&priorityLimit&priorityOrder',
                parent: 'dashboard.user.service-providers.list.details.partnerships',
                abstract: true,
                resolve: {
                    partnershipResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.partnershipResource.decode());
                    },
                    priorityCreateType: function() {
                        return 'create-partnership-priority';
                    }
                },
                views: {
                    '@dashboard.user.service-providers.list.details': {
                        templateUrl: '/modules/common/partnership/tabs.html',
                        controller: 'PartnershipTabsController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().partnershipResponse._links.site.title }}'
                }
            })
            .state('dashboard.user.service-providers.list.details.partnerships.details.info', {
                url: '/info',
                parent: 'dashboard.user.service-providers.list.details.partnerships.details',
                views: {
                    'tab-panel@dashboard.user.service-providers.list.details.partnerships.details': {
                        templateUrl: '/modules/common/partnership/info/partnership-info.html',
                        controller: 'PartnershipInfoController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            })
            .state('dashboard.user.service-providers.list.details.partnerships.details.info.edit', {
                url: '/edit',
                parent: 'dashboard.user.service-providers.list.details.partnerships.details.info',
                views: {
                    '@dashboard.user.service-providers.list.details': {
                        templateUrl: '/modules/common/partnership/edit/partnership-edit.html',
                        controller: 'PartnershipEditController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            })
            .state('dashboard.user.service-providers.list.details.partnerships.details.priorities', {
                url: '/priorities?priorityPage&priorityLimit&priorityOrder&priorityName&priorityStatus',
                parent: 'dashboard.user.service-providers.list.details.partnerships.details',
                views: {
                    'tab-panel@dashboard.user.service-providers.list.details.partnerships.details': {
                        templateUrl: '/modules/common/priorities/list/priorities-list.html',
                        controller: 'PrioritiesListController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    prioritiesCollection: function (apiClient, partnershipResponse, $stateParams) {
                        return apiClient.get(partnershipResponse.getLink('priorities'), angular.extend({}, $stateParams, {
                            page: $stateParams.priorityPage,
                            limit: $stateParams.priorityLimit,
                            order: $stateParams.priorityOrder,
                            name: $stateParams.priorityName,
                            active: $stateParams.priorityStatus
                        }));
                    },
                },
                ncyBreadcrumb: {
                    label: '{{ ::"PRIORITIES" | translate }}'
                },
                params: {
                    priorityPage: '1',
                    priorityLimit: '30',
                    priorityOrder: 'name',
                    priorityName: null,
                    priorityStatus: null
                }
            })
            .state('dashboard.user.service-providers.list.details.partnerships.details.priorities.add', {
                url: '/add',
                parent: 'dashboard.user.service-providers.list.details.partnerships.details.priorities',
                views: {
                    'tab-panel@dashboard.user.service-providers.list.details.partnerships.details': {
                        templateUrl: '/modules/common/priorities/addedit/priorities-addedit.html',
                        controller: 'PrioritiesAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    priorityResponse: function() {
                        return {};
                    },
                    priorityCreateLink: function(prioritiesCollection) {
                        return prioritiesCollection.getLink('create-partnership-priority');
                    },
                    config: function() {
                        return {
                            isIncludeDashboardLimitReached: 'disabled',
                            noBorder: true
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.user.service-providers.list.details.partnerships.details.priorities.details', {
                url: '/details/{priorityResource}',
                abstract: true,
                parent: 'dashboard.user.service-providers.list.details.partnerships.details',
                resolve: {
                    priorityResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.priorityResource.decode());
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().priorityResponse.name }}'
                }
            })
            .state('dashboard.user.service-providers.list.details.partnerships.details.priorities.details.priorityinfo', {
                url: '/info',
                parent: 'dashboard.user.service-providers.list.details.partnerships.details.priorities.details',
                views: {
                    'tab-panel@dashboard.user.service-providers.list.details.partnerships.details': {
                        templateUrl: '/modules/common/priorities/details/info/priorities-info.html',
                        controller: 'PrioritiesDetailsInfoController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    config: function () {
                        return {
                            isIncludeDashboardLimitReached: 'disabled',
                            noBorder: true
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            })
            .state('dashboard.user.service-providers.list.details.partnerships.details.priorities.details.priorityinfo.edit', {
                url: '/edit',
                parent: 'dashboard.user.service-providers.list.details.partnerships.details.priorities.details.priorityinfo',
                views: {
                    'tab-panel@dashboard.user.service-providers.list.details.partnerships.details': {
                        templateUrl: '/modules/common/priorities/addedit/priorities-addedit.html',
                        controller: 'PrioritiesAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    priorityCreateLink: function() {
                        return null;
                    },
                    config: function() {
                        return {
                            isIncludeDashboardLimitReached: 'disabled',
                            noBorder: true,
                            goToStateAfterDelete: 'dashboard.user.service-providers.list.details.partnerships.details.priorities'
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            })
            .state('dashboard.user.service-providers.list.details.partnerships.details.surveys', {
                url: '/surveys',
                parent: 'dashboard.user.service-providers.list.details.partnerships.details',
                resolve: {
                    surveysResponse: function(apiClient, partnershipResponse) {
                        return apiClient.get(partnershipResponse.getLink('surveys')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                views: {
                    'tab-panel@dashboard.user.service-providers.list.details.partnerships.details': {
                        templateUrl: '/modules/common/partnership/surveys/list/partnership-surveys.html',
                        controller: 'PartnershipSurveysController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SURVEYS" | translate }}'
                }
            })
            .state('dashboard.user.service-providers.list.details.partnerships.details.surveys.add', {
                url: '/add',
                parent: 'dashboard.user.service-providers.list.details.partnerships.details.surveys',
                views: {
                    'tab-panel@dashboard.user.service-providers.list.details.partnerships.details': {
                        templateUrl: '/modules/common/partnership/surveys/add/survey-add.html',
                        controller: 'PartnershipSurveyAddController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    surveysRootResponse: function($stateParams, apiClient, rootResourceResponse, partnershipResponse) {
                        return apiClient.get(rootResourceResponse.getLink('surveys'), angular.extend({}, $stateParams, {
                            status: 'active',
                            site: partnershipResponse._links.site.id
                        }));
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SURVEY_ADD" | translate }}'
                }
            })
            .state('dashboard.user.service-providers.list.details.partnerships.details.service-types', {
                url: '/service-types',
                parent: 'dashboard.user.service-providers.list.details.partnerships.details',
                views: {
                    'tab-panel@dashboard.user.service-providers.list.details.partnerships.details': {
                        templateUrl: '/modules/common/service-types/list/service-types.html',
                        controller: 'CommonServiceTypesController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    serviceTypesCollectionResponse: function(apiClient, partnershipResponse) {
                        return apiClient.get(partnershipResponse.getLink('servicetypes')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function() {
                        return {
                            noBorder: true
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SERVICE_TYPES" | translate }}'
                }
            })
            .state('dashboard.user.service-providers.list.details.partnerships.details.service-types.add', {
                url: '/add',
                parent: 'dashboard.user.service-providers.list.details.partnerships.details.service-types',
                views: {
                    'tab-panel@dashboard.user.service-providers.list.details.partnerships.details': {
                        templateUrl: '/modules/common/service-types/add/service-type-add.html',
                        controller: 'CommonServiceTypeAddController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    serviceTypesRootResponse: function($stateParams, apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('servicetypes'), $stateParams).then(function(response) {
                            return response || apiClient.noResourceFound()
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SERVICE_TYPES_ADD" | translate }}'
                }
            }).state('dashboard.user.service-providers.list.details.partnerships.details.webhooks', {
                url: '/webhooks?webhookPage&webhookLimit&webhookOrder&webhookName',
                parent: 'dashboard.user.service-providers.list.details.partnerships.details',
                views: {
                    'tab-panel@dashboard.user.service-providers.list.details.partnerships.details': {
                        templateUrl: '/modules/common/webhooks/list/webhooks-list.html',
                        controller: 'WebhooksListController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    webhooksCollection: function(apiClient, partnershipResponse, $stateParams) {
                        return apiClient.get(partnershipResponse.getLink('webhooks'), angular.extend({}, $stateParams, {
                            page: $stateParams.webhookPage,
                            limit: $stateParams.webhookLimit,
                            order: $stateParams.webhookOrder,
                            name: $stateParams.webhookName
                        }));
                    },
                    webhookCreateType: function() {
                        return 'create-partnership-webhook';
                    }
                },
                params: {
                    webhookPage: '1',
                    webhookLimit: '30',
                    webhookOrder: '-id',
                    webhookName: null
                },
                ncyBreadcrumb: {
                    label: '{{ ::"WEBHOOKS" | translate }}'
                }
            }).state('dashboard.user.service-providers.list.details.partnerships.details.webhooks.add', {
                url: '/add',
                parent: 'dashboard.user.service-providers.list.details.partnerships.details.webhooks',
                views: {
                    'tab-panel@dashboard.user.service-providers.list.details.partnerships.details': {
                        templateUrl: '/modules/common/webhooks/addedit/webhooks-addedit.html',
                        controller: 'WebhooksAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    webhookResponse: function() {
                        return {};
                    },
                    webhookCreateLink: function(webhooksCollection) {
                        return webhooksCollection.getLink('create-partnership-webhook');
                    },
                    config: function() {
                        return {
                            noBorder: true
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.user.service-providers.list.details.partnerships.details.webhooks.details', {
                url: '/details/{webhookResource}',
                abstract: true,
                parent: 'dashboard.user.service-providers.list.details.partnerships.details.webhooks',
                resolve: {
                    webhookResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.webhookResource.decode());
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().webhookResponse.url }}'
                }
            })
            .state('dashboard.user.service-providers.list.details.partnerships.details.webhooks.details.webhooksinfo', {
                url: '/info',
                parent: 'dashboard.user.service-providers.list.details.partnerships.details.webhooks.details',
                views: {
                    'tab-panel@dashboard.user.service-providers.list.details.partnerships.details': {
                        templateUrl: '/modules/common/webhooks/details/info/webhooks-info.html',
                        controller: 'WebhooksDetailsInfoController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    config: function () {
                        return {
                            noBorder: true
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }

            })
            .state('dashboard.user.service-providers.list.details.partnerships.details.webhooks.details.webhooksinfo.edit', {
                url: '/edit',
                parent: 'dashboard.user.service-providers.list.details.partnerships.details.webhooks.details.webhooksinfo',
                views: {
                    'tab-panel@dashboard.user.service-providers.list.details.partnerships.details': {
                        templateUrl: '/modules/common/webhooks/addedit/webhooks-addedit.html',
                        controller: 'WebhooksAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    webhookCreateLink: function(webhooksCollection) {
                        return null;
                    },
                    config: function() {
                        return {
                            noBorder: true
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            })
        ;

    }
})();
