(function () {
    'use strict';

    angular
        .module('elogbooks.admin.roles')
        .controller('UserRolesController', UserRolesController);

    UserRolesController.$inject = ['$state', 'messenger', 'apiClient', 'lodash', 'userResponse', 'roleCollectionResponse', 'userManager'];

    function UserRolesController ($state, messenger, apiClient, lodash, userResponse, roleCollectionResponse, userManager) {
        var vm = this;
        vm.user = userResponse;
        vm.isClientAdminUser = userManager.hasPermission('user_permission_client_admin_manage_users') && !userManager.hasPermission('user_permission_manage_users');

        //fetch roles
        vm.selectedRoles = [];
        vm.roles = roleCollectionResponse.getData().roles;
        apiClient.get(vm.user.getLink('roles'), {limit: 100}).then(function(response){
            vm.selectedRoles = response.roles;

            // Remove from the list of available sites those user already has access to.
            lodash.each(vm.selectedRoles, function(selectedUser) {
                vm.roles = lodash.reject(vm.roles, function(user){
                    return user.id === selectedUser.id;
                });
            });
        });

        vm.revoke = function () {
            vm.selectedRoles = lodash.difference(vm.selectedRoles, vm.revokeAccess);
            vm.roles = lodash.union(vm.roles, vm.revokeAccess);
        };

        vm.grant = function () {
            vm.roles = lodash.difference(vm.roles, vm.grantAccess);
            vm.selectedRoles = lodash.union(vm.selectedRoles, vm.grantAccess);
        };

        vm.submit = function () {
            var data = {
                _links: {
                    role: [
                    ]
                }
            };

            lodash.each(vm.selectedRoles, function(role){
                data._links.role.push(role._links.self);
            });

            apiClient.update(vm.user.getLink('roles'), data).then(function(response){
                if (response) {
                    messenger.success('Updated successfully');
                }
            });
        };
    }

})();
